<template>
  <div>
    <b-card bg-variant="Default" title="Lista Utenti - GoodTable">
        <!-- input search -->
        <div class="custom-search">
          <h5>Ricerca generale (probabilmente Andrea vorrebbe inserirla - vediamo di dropparlo)</h5>
          <b-input-group>
            <b-form-input v-model="campiRicerca.nome"
                  placeholder="ricerca generale"
                  type="text" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click.prevent="ricercaGenerale">
                Cerca
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <hr />

          <h5>Ricerca generale (la nostra preferita)</h5>
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown variant="outline-primary" v-model="campiRicerca.sezione">
                <template #button-content>
                  Sezione
                </template>
                <b-dropdown-item>Action </b-dropdown-item>
                <b-dropdown-item>Another Action</b-dropdown-item>
                <b-dropdown-item>Something else here</b-dropdown-item>
                <b-dropdown-item>Separated link</b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>
              <b-form-input v-model="campiRicerca.nome"
                  placeholder="ricerca generale"
                  type="text" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click.prevent="ricercaGenerale">
                Cerca
              </b-button>
            </b-input-group-append>

          </b-input-group>

          <hr />

          <h5>Visualizzazione demo filtri per "colonne nascoste per responsive design"</h5>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label>Nome:</label>
                <b-form-input
                  placeholder="Name"
                  type="text"
                  class="d-inline-block"
                  @input="advanceFilter"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <hr />
        </div>

        <!-- table -->
        <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :rows="rows"
        :columns="columns"
        :isLoading.sync="isLoading"
        :search-options="{ 
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        
          <div slot="emptystate"> <div class="text-center py-4"> Nessun dato disponibile - (Messaggio personalizzabile) </div> </div>

        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- Column: nome -->
            <div v-if="props.column.field === 'name'">
              {{ props.row.name }} {{ props.row.surname }}
            </div>

            <!-- Column: stato -->
            <span v-else-if="props.column.field === 'stato'">
            <b-badge :variant="statusVariant(props.row.stato)">
              {{ props.row.stato }}
            </b-badge>
            </span>

            <!-- Column: azioni -->
            <span v-else-if="props.column.field === 'azioni'">
              <div class="text-nowrap">
                <feather-icon
                  icon="ArrowRightIcon"
                  class="cursor-pointer text-primary"
                  size="21"
                  v-b-tooltip.hover.v-primary
                  title="Azioni"
                  :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                  @click="sidebarAction(props.row.id, props.row.name+' '+props.row.surname)"
                />
              </div>
            </span>

            <!-- Column: standard -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Visualizzazione da 1 a 
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['10','25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> di {{ props.total }} righe </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
            
    </b-card>

    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <div class="text-center">
        <div class="p-2 pt-0">
          <b-card-text class="mt-2 h4 color-inherit text-reset">
            {{ infoSideBar.titolo }}
          </b-card-text>
          <p>ID riga ->{{ infoSideBar.id }}</p>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
          >
            Modifica
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
          >
            Associa
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
          >
            Altra azione ...
          </b-button>
        </div>
        <hr />
        <div class="p-2">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger" block
            class="mb-1"
          >
            Cancella
          </b-button>
        </div>

      </div>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BRow, BCol, BBadge, BPagination, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Nome',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra per Cognome',
          },
          tdClass: this.tdClassFunc,
        },
        {
          label: 'Email',
          field: 'username',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Cellulare',
          field: 'mobile_phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Ruolo',
          field: 'role',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra', 
            filterDropdownItems: [
              { value: 'Admin', text: 'Admin' }, 
              { value: 'Manager', text: 'Manager' },
              { value: 'Developer', text: 'Developer' },
              { value: 'Assistente', text: 'Assistente' },
            ],
          
          },
        },
        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: '',
            type: ''
            }
        ],
        page: 1, 
        perPage: 10
      },
      campiRicerca: {
        nome: '',
        sezione: '',
      },
      infoSideBar: {
        id: '',
        fullName: '',
        email: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Attivo : 'light-success',
        Disattivo : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    tdClassFunc(row) {
      //console.log("row.field -> "+row.nome)
      if (row.nome == 'Andrea') {
        return 'text-primary';
      }
      return 'text-danger';
    },
    ricercaGenerale(){
      console.log("ricerca generale - chiamata Api per avere la ricerca su tutti i dati e NON solo sui dati dell'attuale viewport della tabella ;-)")
      console.log(this.campiRicerca)

    },
    advanceFilter(val) {
      //this.searchTerm = val

      this.updateParams(val);
      this.loadItems();
    },
    sidebarAction(id, titolo) {
      // params.row - row object 
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
      /*
      console.log(params.row)
      console.log(params.event)
      */
      
      console.log("apertura SIDEBAR AZIONI -> "+id+" -> "+titolo)

      this.infoSideBar = { id: id,
                titolo: titolo, };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    
    updateParams(newProps) {
      console.log(newProps)

      if(newProps.columnFilters.name != ''){
        newProps.columnFilters.surname = newProps.columnFilters.name;
      }

      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      console.log("AGGIORNAMENTO FILTRO COLONNA --------")
      this.updateParams(params);
      this.loadItems();
    },

    loadItems() {
      //console.log("chiamata a dati FAKE ....")
      //console.log("segue aggiornamenti parametri passati all'api - in futuro")
      console.dir(this.serverParams)

      /*
      this.$http.get('/demo-utenti/basic')
        .then(res => { 
          console.log(res.data)
          //console.log(res.data[0].ruolo[0].name)

          this.rows = res.data 
        })
      */

      console.log("chiamata api reale ....")
      this.$http.get('api/crm/user/list', {
            params: this.serverParams 
            }).then(response => { 
                /*
                console.log("risposta vvv"); 
                console.log(response.data)
                console.log('--- ---')
                console.dir(response.data.reply.data)
                console.log("response.data.reply.meta.total -> "+response.data.reply.meta.total);
                */

                
                this.totalRecords = response.data.reply.meta.total;
                this.rows = response.data.reply.data;
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                //this.isLoading = false;
            
            }) 

    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>